import React, { useState } from 'react'
import { FaInstagram, FaInstagramSquare, FaFacebook } from 'react-icons/fa'
import styled from 'styled-components'

import ContentDefault from '../components/container'
import Layout from '../components/layout'
import banner from '../images/banner.png'

const Banner = styled.div`
  width: 100%;
  height: 160px;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const Content = styled(ContentDefault)`
  padding: 50px 0;
  min-height: 800px;

  .form-group {
    input,
    textarea {
      box-shadow: none;
    }

    label {
      font-size: 15px;
      font-weight: 500;
    }
  }

  form {
    button {
      box-shadow: none !important;
      outline: none !important;
    }
  }
`

const Title = styled.h1`
  color: #102549;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
`

const Subtitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
`

const Label = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin: 0;
`

const Text = styled.p``

const External = styled.a`
  display: inline-block;
  margin-bottom: 1rem;
`

const Social = styled.div`
  display: flex;
`

const SocialIcon = styled.a`
  font-size: 22px;
`

const Contato = () => {
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = e => {
    e.preventDefault()

    const nameCl = name.trim()
    const messageCl = message.trim()

    if (nameCl === '' && messageCl === '') return

    const text = `Olá, meu nome é ${nameCl}, e estou eviando essa mensagem pelo site: ${messageCl}`

    if (window) {
      setName('')
      setMessage('')

      window.open(`https://wa.me/5584996437058?text=${text}`)
    }
  }

  return (
    <Layout title='Contato' page='contato'>
      <Banner />
      <Content>
        <Title>Contato</Title>

        <div className='row'>
          <div className='col-12 col-md-6 mb-5'>
            <Subtitle>Aeroclube RN</Subtitle>

            <Text>
              Av. Hermes da Fonseca, 1296 <br /> Tirol, Natal - RN, 59020-650
            </Text>

            <Label>Telefone</Label>
            <External href='tel:+558432111480'>(84) 3211-1480</External>

            <Label>Whatsapp</Label>
            <External href='https://wa.me/5584996437058' target='_blank'>
              (84) 99643-7058
            </External>

            <Label>E-mail</Label>
            <External href='mailto:secretaria@aeroclubern.com.br'>
              secretaria@aeroclubern.com.br
            </External>

            <Label>Redes Sociais</Label>
            <Social>
              <SocialIcon href='https://www.instagram.com/aeroclubern' target='_blank'>
                <FaInstagram />
              </SocialIcon>
              <SocialIcon
                href='https://www.facebook.com/aeroclubepotiguar'
                target='_blank'
                className='ml-3'
              >
                <FaFacebook />
              </SocialIcon>
            </Social>
          </div>
          <div className='col-12 col-md-6 mb-5'>
            <Subtitle>Enviar Mensagem</Subtitle>

            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <label htmlFor='name'>Nome</label>
                <input
                  className='form-control'
                  id='name'
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='message'>Mensagem</label>
                <textarea
                  className='form-control'
                  id='message'
                  rows='4'
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button type='submit' className='btn btn-primary'>
                Enviar Via Whatsapp
              </button>
            </form>
          </div>
        </div>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3969.3803586247086!2d-35.20355444906728!3d-5.801835695775165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b3001c8bddfb05%3A0x66a4d5f1eabeaadd!2sAeroclube%20do%20Rio%20Grande%20do%20Norte%20-!5e0!3m2!1spt-BR!2sbr!4v1588578979864!5m2!1spt-BR!2sbr'
          height='300'
          frameBorder='0'
          style={{ border: 0, width: '100%' }}
          allowFullScreen=''
          aria-hidden='false'
          tabIndex='0'
        ></iframe>
      </Content>
    </Layout>
  )
}

export default Contato
